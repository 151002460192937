html{
  min-height: 100%;
}

.modal {
  max-height: 80%;
  width: 55%;
}
@media only screen and (max-width: 992px) {
  .modal {
    width: 80%;
  }
}

.btn-prefix {
  cursor: pointer;
  border-radius: 50%;
  -webkit-transition: color .3s;
  transition: color .3s;
}
.btn-prefix:hover {
  color: #2bbbad;
}

.input-auto-filled {
  color: blue;
}

.input-field .prefix.right{
  right: 0;
}

.nav-wrapper{
	.dropdown-button{position:absolute; right:0; top : 0;
}
.input-field input[type="search"] ~ .material-icons.icon-close{ right:2rem;}	
}

.profile {
    background: url('/images/t-steele-x-mIAFbAL-o-unsplash.jpg');
    background-size: cover;
    background-position: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

